import { useReducer } from "react";
import { X } from "react-feather";
import { JourneyIcon } from "../../assets/icons/icons";

const NewJourneyModal = ({ navigate, closeModal }) => {
  const formInitValue = {
    journeyName: "",
    description: "",
    errors: {
      journeyName: false,
      description: false,
    },
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.journeyName.trim()) {
          errors.journeyName = true;
        }

        return {
          ...state,
          errors: {
            ...errors,
          },
        };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    let { name, value } = e.target;

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value,
      },
    });
  };

  const createJourney = () => {
    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    const { journeyName, description } = formState;
    if (journeyName) {
      navigate("/journey/build", { state: { name: journeyName, description } });
    }
  };

  return (
    <div className="absolute w-screen h-screen left-0 top-0 bg-gray-950/[.4]">
      <div
        className="flex w-full h-full justify-center items-center"
        onClick={() => closeModal(false)}
      >
        <div
          className="w-[720px] p-8 bg-white rounded-md"
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <div className="flex justify-between mb-4">
              <img src={JourneyIcon} className="w-[60px] h-[60px]" alt="" />
              <div
                className="w-8 h-8 flex justify-center items-center cursor-pointer rounded-full hover:bg-gray-30"
                onClick={() => closeModal(false)}
              >
                <X
                  className="text-gray-600 cursor-pointer rounded-full"
                  size={18}
                />
              </div>
            </div>

            <div
              className={`overflow-auto max-h-[550px]
              }`}
            >
              <div>
                <div className="text-base font-semibold text-gray-700 mb-1">
                  New journey
                </div>
                <div className="text-sm text-gray-500 mb-4">
                  Enter details about this customer journey below before you
                  proceed.
                </div>
                <hr className="border-gray-100" />
              </div>

              <div className="py-4 w-full">
                <div className="py-3">
                  <div className="text-gray-70 font-semibold text-sm mb-1">
                    Journey path name
                  </div>
                  <input
                    type="text"
                    name="journeyName"
                    placeholder="Enter a name for your journey path"
                    value={formState.journeyName}
                    maxLength={50}
                    className={`w-full h-12 px-3 py-3 mt-1 border border-button-border rounded-md focus:outline-none ${
                      formState.errors.journeyName && "border-red-500"
                    }`}
                    onChange={handleFormChange}
                  />
                </div>

                <div className="py-3">
                  <div className="text-gray-70 font-semibold text-sm mb-1">
                    Description (optional)
                  </div>
                  <textarea
                    name="description"
                    placeholder="Enter a description for your journey path"
                    value={formState.description}
                    // maxLength={160}
                    className="w-full h-16 min-h-min px-3 py-3 mt-1 border border-button-border rounded-md focus:outline-none"
                    onChange={handleFormChange}
                  />
                </div>
              </div>

              <div>
                <div className="flex gap-4 justify-end">
                  <button
                    onClick={() => closeModal(false)}
                    className="py-3 px-5 font-semibold rounded-md text-slate-600 border border-gray-40 hover:border-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    className={`py-3 px-7 font-semibold border rounded-md bg-blue-75 text-white hover:bg-green-75
                    }`}
                    type="submit"
                    onClick={createJourney}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewJourneyModal;
