import { Suspense, createContext, lazy, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../../common/components/sidebar";
import Conversations from "../conversations";
import ConversationsOverview from "../conversations/overview";
// import Dashboard from "../dashboard";
import NewDashboard from "../dashboard/NewDashboard";
// import Engagements from "../engagements";
const Engagements = lazy(() => import("../engagements"));
import Settings from "../settings";
import Data from "../data";
import Billing from "../billing";
// import MessagingParent from "../engagements/messagin-parent";
const MessagingParent = lazy(() => import("../engagements/messagin-parent"));
// import CampaignInsights from "../engagements/campaign-insights";
const CampaignInsights = lazy(() => import("../engagements/campaign-insights"));
import { useKeycloak } from "@react-keycloak/web";
import { getRefreshedAccessToken } from "../../common/services/common-api-service";
import {
  createBusiness,
  getTenantBusinessGroups,
} from "../../common/apis/newBusiness";
import CreateBusiness from "../../common/components/create-business";
// import EngagementsRewards from "../engagements/engagements-rewards";
const EngagementsRewards = lazy(
  () => import("../engagements/engagements-rewards")
);
// import EngagementsSurveys from "../engagements/engagements-surveys";
const EngagementsSurveys = lazy(
  () => import("../engagements/engagements-surveys")
);
// import RewardsParent from "../engagements/rewards-parent";
const RewardsParent = lazy(() => import("../engagements/rewards-parent"));
// import SmsParent from "../engagements/sms-parent";
const SmsParent = lazy(() => import("../engagements/sms-parent"));
const JourneyParent = lazy(
  () => import("../engagements/journey-builder-parent")
);
const Journeys = lazy(() => import("../engagements/journey-list"));
// import SurveyParent from "../engagements/survey-parent";
const SurveyParent = lazy(() => import("../engagements/survey-parent"));
// import SurveyDetails from "../engagements/survey-details";
const SurveyDetails = lazy(() => import("../engagements/survey-details"));
import Loader from "../../common/components/loader/Loader";
import Segment from "../audience";
import Recommendation from "../audience/recommendation";
import Profiles from "../audience/profile";
import AudienceApp from "../audience/audienceApp";
import { Provider, useDispatch, useSelector } from "react-redux";
import Pricing from "../pricing/pricing";
import Notifications from "../notifications";
import Logout from "../logout/logout";
import Reports from "../reports";
import CampaignReviewApp from "../engagements/campagin-review";
// import EmailReport from "../engagements/email-reports";
const EmailReport = lazy(() => import("../engagements/email-reports"));
// import RewardReport from "../engagements/reward-reports";
const RewardReport = lazy(() => import("../engagements/reward-reports"));
import {
  startNotificationListener,
  stopNotificationListener,
} from "../../common/services/NotificationService";
import {
  addNewNotification,
  setAccountPermissions,
} from "../../redux-loader/loader-slice/loaderSlice";
import * as Sentry from "@sentry/react";
import SupportTool from "../supportTool";

// import EmailParent from "../engagements/email-parent";
const EmailParent = lazy(() => import("../engagements/email-parent"));
// import RcsParent from "../engagements/rsc-parent";
const RcsParent = lazy(() => import("../engagements/rsc-parent"));
import { getSidebarOptions } from "../../common/apis/sidebar";
import {
  getProductsAccessList,
  getSidebarItemsToShow,
} from "../../common/utils/sidebarOptions";
import { addGAEventListener } from "../../../../marketing-cloud-container/src/ga-events";
import EmailTemplateCreationModal from "../settingsComponents/Engagements/EmailTemplatesTab/templateCreationModal";
import Business from "../business/business";
import BusinessDetails from "../business/business-details";
import DataConnectors from "../data/data-connectors";
import CsvUploads from "../data/csv-data";
import loaderStore from "../../redux-loader/loader-store/loaderStore";
const DataStreams = lazy(() => import("../data/data-streams"));

interface NotificationItem {
  id: string;
  text: number;
  read: boolean;
  thumbnail?: string;
  type: string;
  username: string;
  dateCreated: number;
}

interface NotificationObj {
  totalCount?: number;
  notificationList?: Array<NotificationItem>;
}

export const TenantContext = createContext({});

function Home() {
  const { keycloak } = useKeycloak();
  const [businessDetails, setBusinessDetails] = useState(null);
  const [showCreateBusinessModal, setShowCreateBusinessModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessNameError, setBusinessNameError] = useState("");

  const showCommonLoader = useSelector((state: any) => state.showCommonLoader);
  const notificationList = useSelector((state: any) => state.notificationList);
  const [sidebarOptions, setSidebarOptions] = useState([]);
  const [fetchTenant, setFetchTenant] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    addGAEventListener();
  }, []);

  useEffect(() => {
    const token = keycloak?.token;
    if (keycloak.authenticated && token) {
      fetchTenantGroups(token);
    } else {
      setFetchTenant(Math.random() + 1);
    }
  }, [fetchTenant]);

  useEffect(() => {
    if (keycloak.authenticated) {
      if (businessDetails?.pubnubToken) {
        startNotificationListener(
          businessDetails.pubnubToken,
          pubnubListener,
          businessDetails?.whatsappDetails
        );
      }
    } else {
      stopNotificationListener(
        pubnubListener,
        businessDetails?.whatsappDetails
      );
    }
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("loginIdToken", keycloak.idToken);
      localStorage.setItem("loginAccessToken", keycloak.token);
      localStorage.setItem("refreshToken", keycloak.refreshToken);
    }
  }, [keycloak.authenticated, businessDetails?.pubnubToken]);

  const addNotification = (event: any) => {
    if (event?.subscribedChannel) {
      const {
        channelMetaData,
        type,
        heading,
        status = "",
        id,
        others,
      } = event?.userMetadata;

      const username = channelMetaData?.name || others.businessName;
      const whatsappTitle = username
        ? `Whatsapp message from ${username}`
        : "Whatsapp message";

      let title = "New notification";
      if (type && heading && status) {
        title = `${heading?.trim()}: ${status}`;
      } else if (channelMetaData) {
        title = whatsappTitle;
      }

      const newNotification = {
        title,
        text: event?.message?.text || "",
        // read: !event?.userMetadata?.channelMetaData?.custom?.isUnread,
        isRead: false,
        username,
        id,
        status,
        type: type || "whatsapp",
        campaignType: others?.campaignType,
        dateCreated: new Date(Number(event.timetoken) / 10000).toString(),
      };

      dispatch(addNewNotification(newNotification));
    }
  };

  const handleMessage = (event: any) => {
    addNotification(event);
  };

  const handleStatus = (event: any) => {
    // console.log("handle status called");
    // console.log(event);
  };

  const pubnubListener = {
    status: handleStatus,
    message: handleMessage,
  };

  const fetchTenantGroups = async (token) => {
    try {
      await Promise.all([
        getTenantBusinessGroups(token),
        getSidebarOptions(token),
      ])
        .then((res) => {
          const businessError = res[0].error;
          const businessResponse = res[0].response;
          const sidebarError = res[1].error;
          const sidebarResponse = res[1].response;
          if (
            !businessError ||
            businessError === "false" ||
            !sidebarError ||
            sidebarError === "false"
          ) {
            const sidebarOptionsToShow = getSidebarItemsToShow(sidebarResponse);
            const productList = getProductsAccessList(sidebarResponse);

            setSidebarOptions(sidebarOptionsToShow);
            if (businessResponse && businessResponse.id) {
              const email = keycloak?.tokenParsed?.email;
              dispatch(setAccountPermissions(productList));
              setBusinessDetails({
                ...businessResponse,
                email,
                productList,
              });
              localStorage.setItem(
                "businessName",
                businessResponse.business_name
              );
              localStorage.setItem("businessId", businessResponse.id);
              localStorage.setItem("userId", businessResponse.business_user_id);
              setShowCreateBusinessModal(false);
              setLoading(false);
            } else {
              setShowCreateBusinessModal(true);
              setLoading(false);
            }
          } else {
            businessError.response
              ? Sentry.captureMessage("Error", businessError.response)
              : Sentry.captureMessage("Error", businessError);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const getRefreshedToken = async (res) => {
    const { error, response = {} } = res;
    if (!error || error === "false") {
      const access_token = await getRefreshedAccessToken();
      fetchTenantGroups(access_token);
    } else {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
    }
  };

  const createNewBusiness = async (businessPayload) => {
    try {
      createBusiness(keycloak.token, businessPayload)
        .then(getRefreshedToken)
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          if (err.code === "ERR_BAD_RESPONSE" && err.response.status === 500) {
            setBusinessNameError(
              "Please check that BusinessName can't be duplicate."
            );
          } else {
            //setShowCreateBusinessModal(false);
          }
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      //setShowCreateBusinessModal(false);
    }
  };

  return (
    <Provider store={loaderStore}>
      <TenantContext.Provider value={businessDetails}>
        <div id="app-parent-container" className="App">
          {showCommonLoader && (
            <div className="flex justify-center items-center h-screen w-full absolute z-50">
              <div className="animate-spin grid items-center justify-center">
                <div
                  className={`border-[5px] w-20 h-20 border-t-indigo-950 border-white rounded-[50%]`}
                ></div>
              </div>
            </div>
          )}
          <div
            className={`grid grid-cols-5 gap-1 h-screen overflow-hidden ${
              showCommonLoader ? "opacity-60" : ""
            }`}
          >
            {sidebarOptions && (
              <Sidebar
                sidebarOptions={sidebarOptions}
                showCreateBusinessModal={showCreateBusinessModal}
              />
            )}
            <SupportTool />
            <div className="col-span-4 overflow-scroll">
              {loading && (
                <div className="flex justify-center items-end mt-20">
                  <Loader className="w-20 h-20" />
                </div>
              )}

              {!loading && (
                <Suspense fallback="">
                  <Routes>
                    <Route path="/" element={<NewDashboard />} />
                    <Route path="/audience">
                      <Route index element={<Segment />} />
                      <Route path="create-segment" element={<AudienceApp />} />
                      <Route path="profiles" index element={<Profiles />} />
                      <Route
                        path="recommendation"
                        index
                        element={<Recommendation />}
                      />
                    </Route>
                    <Route path="/dashboard" element={<NewDashboard />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/conversations">
                      <Route
                        path="overview"
                        index
                        element={<ConversationsOverview />}
                      />
                      <Route path=":id" element={<Conversations />} />
                      <Route index element={<Conversations />} />
                    </Route>
                    <Route path="/engagement-review">
                      <Route path=":id" element={<CampaignReviewApp />} />
                    </Route>
                    <Route path="/engagements">
                      <Route path="whatsapp">
                        <Route
                          path=":id"
                          element={<MessagingParent editMode />}
                        />
                        <Route index element={<MessagingParent />} />
                      </Route>
                      <Route path=":id">
                        <Route path="review" element={<CampaignReviewApp />} />
                        <Route index element={<CampaignInsights />}></Route>
                      </Route>
                      <Route path="review">
                        <Route path=":id" element={<CampaignReviewApp />} />
                      </Route>
                      <Route path="rewards">
                        <Route path=":id" element={<RewardReport />} />
                        <Route index element={<EngagementsRewards />}></Route>
                      </Route>
                      <Route path="sms">
                        <Route path=":id" element={<SmsParent />} />
                        <Route index element={<SmsParent />} />
                      </Route>
                      <Route path="email">
                        <Route path=":id" element={<EmailParent />} />
                        <Route index element={<EmailParent />} />
                      </Route>
                      <Route path="email-report">
                        <Route path=":id" element={<EmailReport />} />
                      </Route>
                      <Route path="surveys">
                        <Route path=":id" element={<SurveyDetails />} />
                        <Route index element={<EngagementsSurveys />} />
                      </Route>
                      <Route path="create-reward">
                        <Route path=":id" element={<RewardsParent />} />
                        <Route index element={<RewardsParent />} />
                      </Route>
                      <Route path="create-survey">
                        <Route path=":id" element={<SurveyParent />} />
                        <Route index element={<SurveyParent />} />
                      </Route>
                      <Route path="rcs">
                        <Route path=":id" element={<RcsParent />} />
                        <Route index element={<RcsParent />} />
                      </Route>
                      <Route index element={<Engagements />} />
                    </Route>
                    <Route path="journey">
                      <Route path=":id" element={<Journeys />} />
                      <Route path="build" element={<JourneyParent />} />
                      <Route index element={<Journeys />} />
                    </Route>
                    <Route path="/settings">
                      <Route
                        path="create-template"
                        element={<EmailTemplateCreationModal />}
                      ></Route>
                      <Route index element={<Settings />} />
                    </Route>
                    <Route path="/data">
                      <Route index element={<Data />} />
                      <Route path="connectors" element={<DataConnectors />} />
                      <Route path="csv" element={<CsvUploads />} />
                      <Route path="streams">
                        <Route path=":id" element={<DataStreams />} />
                        <Route index element={<DataStreams />} />
                      </Route>
                    </Route>
                    <Route path="/billing" element={<Billing />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/401" element={<Logout />} />
                    <Route path="/tenants">
                      <Route index element={<Business />} />
                      <Route path="details/:id" element={<BusinessDetails />} />
                    </Route>
                  </Routes>
                </Suspense>
              )}
            </div>
          </div>
          {!businessDetails && (
            <CreateBusiness
              createNewBusiness={createNewBusiness}
              showCreateBusinessModal={showCreateBusinessModal}
              setShowCreateBusinessModal={setShowCreateBusinessModal}
              businessNameError={businessNameError}
              setBusinessNameError={setBusinessNameError}
            />
          )}
        </div>
      </TenantContext.Provider>
    </Provider>
  );
}
export default Home;
