import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { getCurrenySymbol } from "../../common/utils/currenySymbol";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { getRewardList } from "../../common/services/reward-services";
import RewardList from "../reward-list";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { JourneyMix } from "../../assets/icons/icons";
import NewJourneyModal from "../../modals/journey-builder/NewJourneyModal";

const Journeys = (props: any) => {
  const [showNewJourneyModal, setShowNewJourneyModal] = useState(false);
  const [journeyList, setJourneyList] = useState([]);
  const [totalJourneyListLength, setTotalJourneyListLength] = useState(0);
  const [filter, setFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchJourney, setSearchJourney] = useState("");
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [type, setType] = useState("");
  const [audience, setAudience] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const {
    country,
    currency,
    dispatch,
    hasSuperAdminAccess,
    navigate,
    setShowCommonLoader,
    tenantContext,
  } = props;
  const currencySymbol = getCurrenySymbol(country, currency);

  useEffect(() => {
    setFrom(Number(size * pageNumber));
    const params = {
      startDate,
      endDate,
      from,
      size,
      type,
      audience,
      status,
      searchJourney,
    };
    fetchJourneyList(params);
    pageLoadEvent("journey-list_page");
  }, [
    startDate,
    endDate,
    size,
    searchJourney,
    type,
    from,
    audience,
    status,
    pageNumber,
  ]);

  const fetchJourneyList = (params = {}) => {
    /*
    dispatch(setShowCommonLoader(true));
    try {
      getjourneyList(params)
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            setTotalJourneyListLength(res.total);
            setJourneyList(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          dispatch(setShowCommonLoader(false));
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      dispatch(setShowCommonLoader(false));
      console.log(err);
    }
    */
  };

  const openJourneyInfo = () => {
    // window.open("https://terragongroup.com/terragon-journey/", "_blank");
  };

  const createJourney = () => {
    setShowNewJourneyModal(true);
  };

  const onNewJourneyModalClose = (reloadData) => {
    setShowNewJourneyModal(false);
    if (reloadData) true; //fetchJourneyList();
  };

  return (
    <>
      {!filter && pageNumber === 0 && journeyList?.length === 0 ? (
        <div className="flex items-center justify-between w-[64rem] mx-auto mt-48">
          <div className="w-128">
            <div className="text-gray-800 text-4xl font-semibold leading-[2.75rem] mb-4">
              Create your first journey path
            </div>
            <div className="text-gray-70 text-base mb-6">
              Guide your customers along a path that aligns with their needs by
              mapping out journeys.
            </div>
            <div className="flex gap-4">
              <div className="group">
                <button
                  className={`py-3 px-6 text-white text-sm font-semibold border rounded-lg bg-blue-75 relative hover:bg-green-75
                  }`}
                  onClick={createJourney}
                  // disabled={!tenantContext.productList["create_reward_policy"]}
                >
                  Create new journey
                  {/* {!tenantContext.productList["create_reward_policy"] && (
                    <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                      {MESSAGE}
                    </div>
                  )} */}
                </button>
              </div>
              <button
                className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                onClick={openJourneyInfo}
              >
                Learn more
              </button>
            </div>
          </div>
          <img src={JourneyMix} alt="" />
        </div>
      ) : (
        <div>
          {/* <journeyList
            createJourney={createJourney}
            journeyList={journeyList}
            totalJourneyListLength={totalJourneyListLength}
            navigate={props.navigate}
            setFilter={setFilter}
            filter={filter}
            currencySymbol={currencySymbol}
            setSearchJourney={setSearchJourney}
            searchJourney={searchJourney}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            size={size}
            setSize={setSize}
            setFrom={setFrom}
            type={type}
            setType={setType}
            audience={audience}
            setAudience={setAudience}
            status={status}
            setStatus={setStatus}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            hasSuperAdminAccess={hasSuperAdminAccess}
            tenantContext={tenantContext}
          /> */}
        </div>
      )}

      {showNewJourneyModal && (
        <NewJourneyModal
          navigate={navigate}
          closeModal={(status) => onNewJourneyModalClose(status)}
        />
      )}
    </>
  );
};

export default Journeys;
