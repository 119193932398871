import { useCallback, useState, createContext, useEffect } from "react";
import { TfiMoreAlt } from "react-icons/tfi";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import * as Sentry from "@sentry/react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import { nodes, edges } from "./initialElements";
import {
  Chat,
  Condition,
  Envelope,
  List,
  Megaphone1,
  SendEmail,
  SendSms,
  Triggers,
  UsersGroup,
} from "../../../assets/icons/icons";

export const TenantContext = createContext({});

const initialNodes = [
  { id: "1", position: { x: 0, y: 0 }, data: { label: "1" } },
  { id: "2", position: { x: 0, y: 100 }, data: { label: "2" } },
];
const initialEdges = [{ id: "e1-2", source: "1", target: "2" }];
const proOptions = { hideAttribution: true };

const triggers = [
  { name: "Campaign entry", icon: Megaphone1 },
  { name: "List entry", icon: List },
  { name: "On Email Action", icon: Envelope },
  { name: "On SMS Action", icon: Chat },
  { name: "Segment Entry", icon: UsersGroup },
];

const actions = [
  { name: "Send SMS", icon: SendSms },
  { name: "Send Email", icon: SendEmail },
  { name: "Add Conditions", icon: Condition },
];

const JourneyParent = (props: any) => {
  let {
    dispatch,
    navigate,
    setShowCommonLoader,
    setWalletBalance,
    walletBalance,
  } = props;
  const [toolbarParent, setToolbarParent] = useState("triggers");
  const [isToolbarCollapsed, setIsToolbarCollapsed] = useState(false);

  return (
    <TenantContext.Provider value={props.tenantContext}>
      <div>
        <div className="shadow-[0_1px_1px_0_#E5F0FE] mb-0.5">
          <div className="flex justify-between items-center py-4 mx-10">
            <div className="font-semibold">Configure path</div>
            <div className="flex gap-4 items-center">
              <button
                // onClick={() => closeModal(false)}
                onClick={() => navigate("/journey")}
                className="py-3 px-5 font-semibold rounded-md text-slate-600 border border-gray-40 hover:border-gray-200"
              >
                Discard
              </button>
              <button
                className={`py-3 px-5 font-semibold border rounded-md bg-blue-75 text-white hover:bg-green-75
                    }`}
                type="submit"
                // onClick={createJourney}
              >
                Activate
              </button>
              <TfiMoreAlt className="text-slate-500 cursor-pointer" size={22} />
            </div>
          </div>
        </div>

        <div className="bg-gray-60 pt-2">
          {/* Journey Toolbar */}
          <div className="absolute right-10 top-48 z-10">
            <div className="w-72 bg-white">
              <div
                className="w-full px-3 py-2 flex justify-between items-center border border-button-border rounded-t-[4px] cursor-pointer"
                onClick={() => setIsToolbarCollapsed(!isToolbarCollapsed)}
              >
                <img src={Triggers} alt="" />
                <div className="font-semibold text-gray-710">
                  Set triggers and actions
                </div>
                <div>
                  {isToolbarCollapsed ? <FaCaretDown /> : <FaCaretUp />}
                </div>
              </div>
              {!isToolbarCollapsed && (
                <div className="p-4">
                  <div className="flex items-center">
                    {["triggers", "actions"].map((item) => (
                      <div
                        key={item}
                        className={`w-1/2 py-2 mb-4 flex justify-center items-center text-sm text-slate-600 capitalize cursor-pointer ${
                          toolbarParent === item
                            ? "bg-slate-700 font-semibold"
                            : "hover:text-gray-800"
                        }`}
                        onClick={() => setToolbarParent(item)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-2.5 flex-wrap text-gray-70">
                    {toolbarParent === "triggers" &&
                      triggers.map((trigger) => (
                        <div
                          key={trigger.name}
                          className="w-[calc(50%-5px)] h-24 flex flex-col gap-2.5 justify-center items-center rounded-[4px] border cursor-pointer hover:border-slate-600 hover:border-opacity-50"
                        >
                          <img src={trigger.icon} alt="" />
                          <div className="text-xs">{trigger.name}</div>
                        </div>
                      ))}
                    {toolbarParent === "actions" &&
                      actions.map((action) => (
                        <div
                          key={action.name}
                          className="w-[calc(50%-5px)] h-24 flex flex-col gap-2.5 justify-center items-center rounded-[4px] border cursor-pointer hover:border-slate-600 hover:border-opacity-50"
                        >
                          <img src={action.icon} alt="" />
                          <div className="text-xs">{action.name}</div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="h-[85vh] w-full">
            <ReactFlow
              // nodes={initialNodes}
              // edges={initialEdges}
              // nodes={nodes}
              // edges={edges}
              proOptions={proOptions}
            >
              {/* <Controls /> */}
              {/* <Background /> */}
            </ReactFlow>
          </div>
        </div>
      </div>
    </TenantContext.Provider>
  );
};

export default JourneyParent;
