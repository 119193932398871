import { useEffect, useState } from "react";
import Bee from "@mailupinc/bee-plugin";
import EmailBuilder from "../email-builder/email-builder";
import EmailStepTwoInputs from "./email-step-two-inputs";
import { getSegmentVariables } from "../../../common/services/message-services";
import { CUSTOMER_ID } from "../../../common/constants/billingConstants";
import env from "../../../../../marketing-cloud-main/env.json";

const EmailStepTwo = (props: any) => {
  const [attributeList, setAttributeList] = useState([]);
  const [defaultVariables, setDefaultVariables] = useState([]);

  const enablePersonalization = () => {
    return (
      props?.data?.audience?.segmentId &&
      props?.data?.selectedSegment &&
      props?.data?.selectedSegment.audience_source !== "terragon"
    );
  };

  const getAttributeList = () => {
    return attributeList.map((el) => ({
      name: el.display_name,
      value: `{{${el.column_name}}}`,
    }));
  };

  useEffect(() => {
    if (props.data.content.default?.length) {
      setDefaultVariables(props.data.content.default);
      return;
    }

    if (attributeList && attributeList.length) {
      const defaultNames = attributeList.map((attr) => {
        return {
          fieldName: attr.column_name,
          defaultName: attr.display_name,
        };
      });
      setDefaultVariables([...defaultNames]);
    }
  }, [attributeList, props.data]);

  useEffect(() => {
    if (enablePersonalization())
      getPersonalizationVariables(props?.data?.selectedSegment?.segment_ids);
  }, [props.data]);

  const getPersonalizationVariables = (segmentId) => {
    try {
      getSegmentVariables(segmentId)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            let list = res.data;
            setAttributeList(list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const beeEmailBuilder = new Bee();

  const [savedTemplate, setSavedTemplate] = useState(null);

  useEffect(() => {
    if (props.data?.content?.jsonContent) setSavedTemplate(props.data?.content);
  }, [props.data]);

  const beeConfig = {
    uid:
      env.CLIENT_ID === "mc-client-staging"
        ? `staging-${CUSTOMER_ID}`
        : `prod-${CUSTOMER_ID}`,
    mergeTags: getAttributeList(),
    container: "bee-plugin-container",
    language: "en-US",
    onSave: (jsonFile, htmlFile) => {
      setEmailContent({ jsonContent: jsonFile, htmlContent: htmlFile });
    },
  };
  const [emailContent, setEmailContent] = useState({
    jsonContent: {},
    htmlContent: "",
  });

  return (
    <div>
      <EmailStepTwoInputs
        data={props.data}
        setStep={props.setStep}
        setData={props.setData}
        country={props.country}
        currencySymbol={props.currencySymbol}
        navigate={props.navigate}
        beeEmailBuilder={beeEmailBuilder}
        emailContent={emailContent}
        setSavedTemplate={setSavedTemplate}
        defaultVariables={defaultVariables}
      />
      <div className="h-[640px] border rounded relative m-12 mt-0">
        {/* <div className="absolute h-[55px] w-[300px] bg-[#515658] right-0"></div>
        <div className="absolute"></div> */}
        <EmailBuilder
          beeEmailBuilder={beeEmailBuilder}
          beeConfig={beeConfig}
          savedTemplate={savedTemplate?.jsonContent || null}
        />
      </div>
    </div>
  );
};

export default EmailStepTwo;
